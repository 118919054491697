import JoditEditor from "jodit-react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

import React, { useEffect, useState } from "react";
import AuthFormDataHeader from "../../AuthFormDataHeader";
import { useNavigate, useParams } from "react-router-dom";
export default function UpdateContent() {
  const navigate = useNavigate();
  const [contentEdit, setContentEdit] = useState({
    title: "",
    description: "",
    status: "",
    content_type: "",
    content_media: "",
  });
  const { public_id } = useParams();
  const base_url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const EditFaqId = async () => {
      try {
        const resData = await fetch(
          `${base_url}content/${public_id}/`,
          {
            method: "PUT",
            headers: AuthFormDataHeader(),
          }
        );
        const res = await resData.json();
        setContentEdit(res);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };
    EditFaqId();
  }, []);


  const descriptionChanged = (data) => {
    setContentEdit({ ...contentEdit, description: data });
  };
  const ContentMediaChanged = (e) => {
    setContentEdit({
      ...contentEdit,
      content_media: e.target.files[0],
    });
  };


  const handleEdit = (e) => {
    setContentEdit({ ...contentEdit, [e.target.name]: e.target.value });
  };
  const handleFaqUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", contentEdit.title);
    formData.append("description", contentEdit.description);
    formData.append("status", contentEdit.status);
    formData.append("content_type", contentEdit.content_type);
    formData.append("content_media", contentEdit.content_media);

    const config = {
      headers: AuthFormDataHeader(),
    };

    try {
      const response = await axios.put(
        `${base_url}content/${public_id}/`,
        formData,
        config
      );

      console.log(response.data);
      setContentEdit({
        ...contentEdit,
        content_media: "", // Clear the file input value
      });

      setTimeout(() => navigate("/content"), 500);
      toast.success("Update Successful");
    } catch (error) {
      console.error("Error updating content:", error);
      toast.error("Update Failed");
    }
  };



  return (
    <>
      <section className="content main-header content-wrapper-scroll">
        <div className="container-fluid">
          <Helmet>
            <title>Content | Content update</title>
          </Helmet>
          <div className="row">
            <div className="col-sm-6 my-2">
              <h4 className="text-dark">Content</h4>
            </div>
            <div className="col-sm-6 my-2">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/content">Content List</a>
                </li>
                <li className="breadcrumb-item active">Edit Content </li>
              </ol>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Update Content</h3>
                </div>
                <div className="card-body">
                  <form onSubmit={handleFaqUpdate}  enctype="multipart/form-data">
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Title</label>
                        <input
                          value={contentEdit.title}
                          onChange={(e) => handleEdit(e)}
                          type="text"
                          className="form-control"
                          id="title"
                          placeholder="questions"
                          name="title"
                        />
                      </div>
                      <section className="content my-3">
                        <div className="row">
                          <div className="col-md-12">
                            <label>Description</label>
                          </div>
                          <div className="mb-3">
                            <JoditEditor
                              value={contentEdit.description}
                              onChange={(newDescription) =>
                                descriptionChanged(newDescription)
                              }
                              name="description"
                            />
                          </div>
                        </div>
                      </section>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Content Media
                        </label>
                        <input
                          onChange={(data) => ContentMediaChanged(data)}
                          type="file"
                          className="form-control"
                          id="content_media"
                        />
                        <input
                          type="text"
                          className="form-control"
                          id="content_media"
                          value={contentEdit.content_media}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Content Type</label>
                        <select
                          onChange={(e) => handleEdit(e)}
                          className="form-control select2 select2-danger"
                          name="content_type"
                          id="content_type"
                          type="select"
                          value={contentEdit.content_type}
                        >
                          <option value="image">Image</option>
                          <option value="video">Video</option>
                          <option value="audio">Audio</option>
                          <option value="text">Text File</option>
                        </select>
                      </div>
                      <label>Status</label>
                      <select
                        onChange={(e) => handleEdit(e)}
                        className="form-control select2 select2-danger"
                        name="status"
                        id="status"
                        type="select"
                        value={contentEdit.status}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                      <button
                        type="button"
                        className="btn btn-primary my-3 me-2"
                        onClick={handleFaqUpdate}
                      >
                        Update
                      </button>

                      <button type="button" className="btn btn-info my-3">
                        <a
                          className="text-white text-decoration-none"
                          href="/content"
                        >
                          {" "}
                          Back
                        </a>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}
