import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AuthHeader from "../AuthHeader";
import Image from "../components/Image";

const Permissions = () => {
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const [status, setStatus] = useState();
  const [sort, setSort] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const base_url = process.env.REACT_APP_BASE_URL;

  let limit = 10;
  useEffect(() => {
    setTimeout(() => {
      getPermission();
    }, 20);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [search, status, limit, sort, page]);

  const getPermission = async () => {
    try {
      const res = await fetch(`${base_url}permission/`, {
        method: "GET",
        headers: AuthHeader(),
      });
      const data = await res.json();
      const total = data.count;
      setCount(total);
      setPageCount(Math.ceil(total / limit));
      setItems(data.results);
    } catch (error) {
      console.log("err", error);
    }
  };

  const result = items.map((item, index) => {
    return (
      <MDBTableBody key={item.id}>
        <tr>
          <td key={index + 1}>{index + 1}</td>
          <td className="">{item.name}</td>
          <td className="">{item.model}</td>
          <td className="">{item.app_label}</td>
        </tr>
      </MDBTableBody>
    );
  });

  return (
    <section className="content main-header faq-content">
      <div className="container-fluid">
        <Helmet>
          <title>Permission List | Permission</title>
        </Helmet>
        <div className="row">
          <div className="col-sm-6 my-2">
            <h4 className="text-dark">Permission</h4>
          </div>
          <div className="col-sm-6 my-2">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active">Permission List</li>
            </ol>
          </div>
          <div className="col-12">
            <div className="card faq-wrapper">
              <div className="card-header">
                <span className="font-weight-bold">Permission List</span>
                <a href="/add-permission" className="float-right ">
                  <i className="fa fa-plus"></i> Add new
                </a>
              </div>
              <div className="card-body">
                <table className="table table-hover text-nowrap sortable faq-list">
                  <MDBTableHead>
                    <tr>
                      <th className="index">Index</th>
                      <th>Name</th>
                      <th>model</th>
                      <th>App Label</th>
                    </tr>
                  </MDBTableHead>

                  {isLoading ? (
                    <SkeletonTheme baseColor="#ebebeb" highlightColor="#f5f5f5">
                      <MDBTableBody>
                        {Array(8)
                          .fill()
                          .map((item, index) => (
                            <tr key={index}>
                              <td>
                                <h1>
                                  {" "}
                                  <Skeleton />
                                </h1>
                              </td>
                              <td>
                                <h1>
                                  {" "}
                                  <Skeleton />
                                </h1>
                              </td>
                              <td>
                                <h1>
                                  {" "}
                                  <Skeleton />
                                </h1>
                              </td>
                            </tr>
                          ))}
                      </MDBTableBody>
                    </SkeletonTheme>
                  ) : (
                    result
                  )}
                </table>
                {items.length === 0 ? <Image /> : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Permissions;
