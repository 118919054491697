import JoditEditor from "jodit-react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

import React, { useEffect, useState } from "react";
import AuthHeader from "../../AuthHeader";

import { useNavigate, useParams } from "react-router-dom";
export default function UpdateCategory() {
  const navigate = useNavigate();
  const [faqEdit, setFaqEdit] = useState({
    title: "",
    description: "",
    status: "",
    slug: "",
    parent_id: "",
    image: "",
  });
  const { public_id } = useParams();
  const [selectItem, setSelectItem] = useState([]);
  const base_url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const EditFaqId = async () => {
      const resdata = await fetch(`${base_url}categories/${public_id}/`, {
        method: "PUT",
        headers: AuthHeader(),
      });
      const res = resdata.json();
      setFaqEdit(await res);
    };
    fetch(`${base_url}category/items/`, { headers: AuthHeader() })
      .then((response) => response.json())
      .then((data) => setSelectItem(data.results));
    EditFaqId();
  }, []);

  const descriptionChanged = (data) => {
    setFaqEdit({ ...faqEdit, description: data });
  };
  const slugChanged = (e) => {
    setFaqEdit({ ...faqEdit, slug: e.target.value });
  };
  const imageChanged = (e) => {
    setFaqEdit({ ...faqEdit, image: e.target.value });
  };
  const parentIdChanged = (e) => {
    setFaqEdit({ ...faqEdit, parent_id: e.target.value });
  };
  const handleEdit = (e) => {
    setFaqEdit({ ...faqEdit, [e.target.name]: e.target.value });
  };
  const handleFaqUpdate = async (e) => {
    e.preventDefault();

    const response = await axios
      .put(`${base_url}categories/${public_id}/`, faqEdit, {
        headers: AuthHeader(),
      })
      .then(() => {
        setTimeout(() => navigate("/categories"), 500);
        toast.success("Update SuccessFull");
      });
  };

  return (
    <>
      <section className="content main-header faq-content content-wrapper-scroll">
        <div className="container-fluid">
          <Helmet>
            <title>Categories | Categories upadte</title>
          </Helmet>
          <div className="row">
            <div className="col-sm-6 my-2">
              <h4 className="text-dark">FAQ's</h4>
            </div>
            <div className="col-sm-6 my-2">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/categories">Categories List</a>
                </li>
                <li className="breadcrumb-item active">Edit Categories </li>
              </ol>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Update Categories</h3>
                </div>
                <div className="card-body">
                  <form onSubmit={handleFaqUpdate}>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Title</label>
                        <input
                          value={faqEdit.title}
                          onChange={(e) => handleEdit(e)}
                          type="text"
                          className="form-control"
                          id="title"
                          placeholder="questions"
                          name="title"
                        />
                      </div>
                      <section className="content my-3">
                        <div className="row">
                          <div className="col-md-12">
                            <label>Deacription</label>
                          </div>
                          <div className="mb-3">
                            <JoditEditor
                              // ref={editor}
                              value={faqEdit.description}
                              onChange={(newDescription) =>
                                descriptionChanged(newDescription)
                              }
                              name="description"
                            />
                          </div>
                        </div>
                      </section>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Slug</label>
                        <input
                          type="text"
                          className="form-control"
                          id="slug"
                          placeholder="slug"
                          name="slug"
                          onChange={(e) => slugChanged(e)}
                          value={faqEdit.slug}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Image Url</label>
                        <input
                          type="text"
                          className="form-control"
                          id="image"
                          placeholder="image url"
                          name="image"
                          onChange={(e) => imageChanged(e)}
                          value={faqEdit.image}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Parent id</label>
                        <select
                          onChange={(e) => parentIdChanged(e)}
                          className="form-control select2 select2-danger"
                          name="parentId"
                          id="parentId"
                          type="select"
                          value={faqEdit.parent_id}
                        >
                          {selectItem.map((option, index) => (
                            <option key={index + 1} value={option.id}>
                              {option.title}
                            </option>
                          ))}
                        </select>
                      </div>
                      <label>Status</label>
                      <select
                        onChange={(e) => handleEdit(e)}
                        className="form-control select2 select2-danger"
                        name="status"
                        id="status"
                        type="select"
                        value={faqEdit.status}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                      <button
                        type="button"
                        className="btn btn-primary my-3 me-2"
                        onClick={handleFaqUpdate}
                      >
                        Update
                      </button>

                      <button type="button" className="btn btn-info my-3">
                        <a
                          className="text-white text-decoration-none"
                          href="/categories"
                        >
                          {" "}
                          Back
                        </a>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}
