import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Helmet } from "react-helmet";
import AuthHeader from "../AuthHeader";

const AddPermission = () => {
  const navigate = useNavigate();
  const [permissionData, setPermission] = useState({
    name: "",
    codename: "",
  });

  const base_url = process.env.REACT_APP_BASE_URL;

  const fieldChanged = (e) => {
    setPermission({ ...permissionData, [e.target.name]: e.target.value });
  };

  const createPost = (event) => {
    event.preventDefault();
    const response = axios
      .post(`${base_url}permission/create/`, permissionData, {
        headers: AuthHeader(),
      })
      .then((response) => response.data)
      .then((data) => {
        toast.success("Permission Page Created !!");
        setPermission({
          name: "",
          codename: "",
        });
        navigate("/permission");
      })
      .catch((error) => {
        toast.error("permission Page not created due to some error !!");
      });
  };

  return (
    <>
      <section className="content main-header content-wrapper-scroll">
        <div className="container-fluid">
          <Helmet>
            <title>Permission | Permission add</title>
          </Helmet>
          <div className="row">
            <div className="col-sm-6 my-2">
              <h4 className="text-dark">Permission Page</h4>
            </div>
            <div className="col-sm-6 my-2">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/permission">Permission List</a>
                </li>
                <li className="breadcrumb-item active">Add Permission </li>
              </ol>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Add Permission</h3>
                </div>
                <div className="card-body">
                  <form onSubmit={createPost}>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="name"
                          name="name"
                          onChange={fieldChanged}
                        />
                      </div>
                      <label htmlFor="exampleInputEmail2">Code Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="codename"
                        placeholder="codename"
                        name="codename"
                        onChange={fieldChanged}
                      />
                      <div>
                        <button
                          type="button"
                          className="btn btn-success my-3"
                          onClick={createPost}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
    </>
  );
};

export default AddPermission;
