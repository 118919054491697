export default function AuthHeader() {
    const user = JSON.parse(localStorage.getItem("user-info"));
  
    if (user && user.access) {
      return {
        Authorization: "Bearer " + user.access,
        "Content-Type": "application/json",
      };
    } else {
      return {};
    }
  }
  