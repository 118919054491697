import "react-toastify/dist/ReactToastify.css";
import parse from 'html-react-parser';
import AuthHeader from "../../AuthHeader";
import { Helmet } from "react-helmet";

import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
export default function DetailCategory() {
  const [faqEdit, setFaqEdit] = useState({
    title: "",
    description: "",
    status: "",
    public_id: "",
    slug: "",
    parent_id: "",
    image: ""
  });
  const [selectItem, setSelectItem] = useState([]);

  const { public_id } = useParams();
  const base_url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const EditFaqId = async () => {
      const resData = await fetch(
        `${base_url}categories/${public_id}`,
        {
          method: "GET",
          headers: AuthHeader(),
        }
      );
      const res = resData.json();
      setFaqEdit(await res);
    };
    fetch(`${base_url}category/items/`, { headers: AuthHeader() })
      .then((response) => response.json())
      .then((data) => setSelectItem(data.results));
    EditFaqId();
  }, []);

  return (
    <>
      <section className="content main-header content-wrapper-scroll">
        <div className="container-fluid">
          <Helmet>
            <title>Categories | Categories detail</title>
          </Helmet>
          <div className="row">
            <div className="col-sm-6 my-2">
              <h4 className="text-dark">Categories</h4>
            </div>
            <div className="col-sm-6 my-2">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/categories">Categories List</a>
                </li>
                <li className="breadcrumb-item active">Detail Categories </li>
              </ol>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Detail Categories</h3>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group">
                      <h4>Title</h4>
                      {faqEdit.title}
                    </div>
                    <section className="content my-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div>
                            <div>
                              <h4>Description</h4>
                            </div>
                            <div>{parse(faqEdit.description)}</div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <div className="form-group">
                      <h4>Slug</h4>
                      {faqEdit.slug}
                    </div>
                    <div className="form-group">
                      <h4>Image Url</h4>
                      {faqEdit.image}
                    </div>
                    <div className="form-group">
                      {" "}
                      <h4>Parent category</h4>
                      <div className="parentId">
                        {selectItem.map((pItem) =>
                          faqEdit.parent_id === pItem.id ? pItem.title : ""
                        )}
                      </div>
                    </div>
                    <h4>Status</h4>
                    <div>
                      {faqEdit.status === "active" ? (
                        <span className="badge bg-success">
                          {faqEdit.status}
                        </span>
                      ) : (
                        <span className="badge bg-danger">
                          {faqEdit.status}
                        </span>
                      )}
                    </div>
                    <br />
                    <h4>Public id</h4>
                    <div>{faqEdit.public_id}</div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
