import React, { useState } from "react";

const ResetPassword = () => {
  const [login, setLogin] = useState({
    email: "",
  });
  const [errorMsg, setErrorMsg] = useState(false);
  const [emptyMsg, setEmptyMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [spinner, setSpinner] = useState("");
  const base_url = process.env.REACT_APP_BASE_URL;

  const handleChange = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value,
    });
  };

  async function submitForm() {
    setSpinner("spinner-border spinner-border-sm mr-2");
    let item = login;
    let result = fetch(`${base_url}auth/users/reset_password/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(item),
    })
      .then(function (res) {
        console.log("res", res);
        setErrorMsg(false);
        setEmptyMsg(false);
        setSuccessMsg("Email sent");
        setSpinner("");
        return res.json();
      })
      .then(function (resJson) {
        console.log("resJson", resJson);
        return resJson;
      });
    result = await result;
    console.log("result", result);

    if (result.email) {
      if (result.email[0] === "Enter a valid email address.") {
        setErrorMsg(true);
        setSuccessMsg("");
        setEmptyMsg(false);
        setSpinner("");
      }
      if (result.email[0] === "This field may not be blank.") {
        setErrorMsg(false);
        setSuccessMsg("");
        setEmptyMsg(true);
        setSpinner("");
      }
    }
  }

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="login-logo">
          <img
            src="/static/assets/images/gcs.png"
            className="img-fluid"
            alt="intelligens"
          />
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg ">Enter Your Registered Email</p>
            {errorMsg ? (
              <p className="text-danger">Invalid Email</p>
            ) : emptyMsg ? (
              <p className="text-danger">Empty Email</p>
            ) : (
              ""
            )}
            {successMsg ? <p style={{ color: "green" }}>{successMsg}</p> : ""}
            <form onSubmit={submitForm}>
              <div className="input-group ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  value={login.email}
                  id="email"
                  name="email"
                  onChange={handleChange}
                  style={
                    errorMsg || emptyMsg
                      ? { borderColor: "red" }
                      : successMsg
                      ? { borderColor: "green" }
                      : null
                  }
                />
                <div className="input-group-append ">
                  <div
                    className="input-group-text"
                    style={
                      errorMsg || emptyMsg
                        ? { borderColor: "red" }
                        : successMsg
                        ? { borderColor: "green" }
                        : null
                    }
                  >
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary btn-block mt-3"
                onClick={submitForm}
              >
                <span
                  className={spinner}
                  role="status"
                  aria-hidden="true"
                ></span>
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
