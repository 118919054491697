export default function AuthFormDataHeader() {
  const user = JSON.parse(localStorage.getItem("user-info"));

  if (user && user.access) {
    return {
      Authorization: "Bearer " + user.access,
      "content-type": "multipart/form-data",
    };
  } else {
    return {};
  }
}
