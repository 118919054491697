import "react-toastify/dist/ReactToastify.css";
import parse from 'html-react-parser';

import { Helmet } from "react-helmet";

import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import AuthHeader from "../../AuthHeader";

export default function DetailContent() {
  const [contentEdit, setContentEdit] = useState({
    title: "",
    description: "",
    status: "",
    public_id: "",
    content_media: "",
    content_type: "",

  });
  const { public_id } = useParams();
  const base_url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const EditContentId = async () => {
      const resData = await fetch(`${base_url}content/${public_id}`, {
        method: "GET",
        headers: AuthHeader(),
      });
      const res = resData.json();
      setContentEdit(await res);
    };
    EditContentId();
  }, []);

  function imageZoom() { }

  return (
    <>
      <section className="content main-header content-wrapper-scroll">
        <div className="container-fluid">
          <Helmet>
            <title>Content | Content detail</title>
          </Helmet>
          <div className="row">
            <div className="col-sm-6 my-2">
              <h4 className="text-dark">Content</h4>
            </div>
            <div className="col-sm-6 my-2">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/content">Content List</a>
                </li>
                <li className="breadcrumb-item active">Detail Content </li>
              </ol>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Detail Content</h3>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group">
                      <h4>Title</h4>
                      {contentEdit.title}
                    </div>
                    <section className="content my-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div>
                            <div>
                              <h4>Description</h4>
                            </div>
                            <div>
                              {parse(contentEdit.description)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <div className="form-group">
                      <h4>Media</h4>
                      {contentEdit.content_type === "image" ? (
                        <img
                          src={contentEdit.content_media}
                          alt={contentEdit.title}
                          style={{ width: "300px" }}
                          onClick={imageZoom}
                        />
                      ) : contentEdit.content_type === "video" ? (
                        <video width="500px" controls>
                          <source
                            src={contentEdit.content_media}
                            type="video/mp4"
                          />
                        </video>
                      ) : contentEdit.content_type === "text" ? (
                        <>text</>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-group">
                      <h4>Media Type</h4>
                      {contentEdit.content_type}
                    </div>
                    <div className="form-group">
                      <h4>Content Categories</h4>
                      {contentEdit.content_category}
                    </div>
                    <h4>Status</h4>
                    <div>
                      {contentEdit.status === "active" ? (
                        <span className="badge bg-success">
                          {contentEdit.status}
                        </span>
                      ) : (
                        <span className="badge bg-danger">
                          {contentEdit.status}
                        </span>
                      )}
                    </div>
                    <br />
                    <h4>Public id</h4>
                    <div>{contentEdit.public_id}</div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
