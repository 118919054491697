import React, { useState, useEffect } from "react";
import JoditEditor from "jodit-react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Helmet } from "react-helmet";
import AuthHeader from "../../AuthHeader";

const AddCms = () => {
  const navigate = useNavigate();
  // const editor = useRef(null);
  const [faqData, setFaq] = useState({
    title: "",
    description: "",
    status: "publish",
    slug: "",
  });

  const [titleErr, setTitleErr] = useState(false);
  const [descErr, setDescErr] = useState(false);
  const [slugErr, setSlugErr] = useState(false);
  const base_url = process.env.REACT_APP_BASE_URL;

  const fieldChanged = (e) => {
    let item = e.target.value;
    if (item.length === 0) {
      setTitleErr(true);
    } else {
      setTitleErr(false);
    }
    setFaq({ ...faqData, [e.target.name]: e.target.value });
  };

  const descriptionChanged = (data) => {
    if (data.length === 0) {
      setDescErr(true);
    } else {
      setDescErr(false);
    }
    setFaq({ ...faqData, description: data });
  };

  const statusChanged = (event) => {
    setFaq({ ...faqData, status: event.target.value });
  };

  const slugChange = (event) => {
    if (event.length === 0) {
      setSlugErr(true);
    } else {
      setSlugErr(false);
    }
    setFaq({ ...faqData, slug: event.target.value });
  };

  const createPost = (event) => {
    event.preventDefault();

    if (
      faqData.title.trim() === "" &&
      faqData.description.trim() === "" &&
      faqData.slug.trim() === ""
    ) {
      toast.error("category content is required !!");
      setDescErr(true);
      setTitleErr(true);
      setSlugErr(true);
    }

    if (faqData.title.trim() === "") {
      toast.error("category  title is required !!");
      setTitleErr(true);
    }

    if (faqData.description.trim() === "") {
      toast.error("category description is required !!");
      setDescErr(true);
    }

    if (faqData.slug.trim() === "") {
      toast.error("category slug is required !!");
      setSlugErr(true);
    }

    if (!titleErr && !descErr && !slugErr) {
      const response = axios
        .post(`${base_url}cms/create/`, faqData, {
          headers: AuthHeader(),
        })
        .then((response) => response.data)
        .then((data) => {
          toast.success("CMS Page Created !!");
          setFaq({
            title: "",
            description: "",
            status: "",
            slug: "",
            parent_id: "",
          });
          navigate("/cms");
        })
        .catch((error) => {
          toast.error("CMS Page not created due to some error !!");
        });
    }
  };

  return (
    <>
      <section className="content main-header content-wrapper-scroll">
        <div className="container-fluid">
          <Helmet>
            <title>CMS | CMS add</title>
          </Helmet>
          <div className="row">
            <div className="col-sm-6 my-2">
              <h4 className="text-dark">CMS Page</h4>
            </div>
            <div className="col-sm-6 my-2">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/cms">CMS List</a>
                </li>
                <li className="breadcrumb-item active">Add CMS </li>
              </ol>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Add CMS</h3>
                </div>
                <div className="card-body">
                  <form onSubmit={createPost}>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Title</label>
                        <input
                          type="text"
                          className="form-control"
                          id="title"
                          placeholder="questions"
                          name="title"
                          onChange={fieldChanged}
                          style={titleErr ? { borderColor: "red" } : null}
                        />
                        {titleErr ? (
                          <span style={{ color: "red" }}>title is empty</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <label htmlFor="exampleInputEmail1">Description</label>

                      <div className="mb-3">
                        <JoditEditor
                          // ref={editor}
                          value={faqData.description}
                          onChange={(newDescription) =>
                            descriptionChanged(newDescription)
                          }
                        />
                        {descErr ? (
                          <span style={{ color: "red" }}>
                            description is empty
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Slug</label>
                        <input
                          type="text"
                          className="form-control"
                          id="slug"
                          placeholder="slug"
                          name="title"
                          onChange={slugChange}
                          style={slugErr ? { borderColor: "red" } : null}
                        />
                        {slugErr ? (
                          <span style={{ color: "red" }}>slug is empty</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <label>Status</label>
                      <select
                        onChange={statusChanged}
                        className="form-control select2 select2-danger"
                        name="status"
                        id="status"
                        type="select"
                        value={faqData.status}
                      >
                        <option value="publish">Publish</option>
                        <option value="unpublish">UnPublish</option>
                      </select>
                      <div>
                        <button
                          type="button"
                          className="btn btn-success my-3"
                          onClick={createPost}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
    </>
  );
};

export default AddCms;
