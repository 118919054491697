import React, { useState, useEffect } from "react";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import AuthHeader from "./AuthHeader";
import { useNavigate } from "react-router-dom";

const UpdatePassword = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState({
    current_password: "",
    new_password: "",
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [spinner, setSpinner] = useState("");
  const base_url = process.env.REACT_APP_BASE_URL;


  const handleChange = (e) => {
    setLogin({ ...login, current_password: e.target.value });
  };
  const newPasswordHandle = (e) => {
    setLogin({ ...login, new_password: e.target.value });
  };

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  async function submitForm() {
    setSpinner("spinner-border spinner-border-sm mr-2");
    const item = login;
    console.log("login", item);
    console.log("JSON.stringify(item)", JSON.stringify(item));
    console.log("item", item);
    let result = fetch(`${base_url}change_password/`, {
      method: "POST",
      headers: AuthHeader(),
      body: JSON.stringify(item),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (resJson) {
        return resJson;
      });
    result = await result;
    console.log("result", result);
    if (result.error) {
      setErrorMsg(result.error);
      setSpinner("");
    } else {
      setErrorMsg("");
      setSpinner("");
      localStorage.removeItem("user-info")
      navigate("/login");
    }
  }

  useEffect(() => {
    document.title = "Update Password";
  });

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="login-logo">
          <img
            src="/static/assets/images/gcs.png"
            className="img-fluid"
            alt="intelligens"
          />
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg ">Change Your Password</p>
            {errorMsg.length > 0 ? (
              <p className="text-danger">{errorMsg}</p>
            ) : (
              ""
            )}
            <form onSubmit={submitForm}>
              <div className="input-group ">
                <input
                  type={type}
                  className="form-control"
                  placeholder="Current Password"
                  id="cureentpassword"
                  name="password"
                  onChange={handleChange}
                  style={errorMsg.length > 0 ? { borderColor: "red" } : null}
                />
                <div className="input-group-append">
                  <div
                    className="input-group-text"
                    style={errorMsg.length > 0 ? { borderColor: "red" } : null}
                  >
                    <span onClick={handleToggle}>
                      <Icon icon={icon} size={20} />
                    </span>
                  </div>
                </div>
              </div>
              <div className="input-group mt-3">
                <input
                  type={type}
                  className="form-control"
                  placeholder="New Password"
                  id="newpassword"
                  name="newpassword"
                  onChange={newPasswordHandle}
                  style={errorMsg.length > 0 ? { borderColor: "red" } : null}
                />
                <div className="input-group-append">
                  <div
                    className="input-group-text"
                    style={errorMsg.length > 0 ? { borderColor: "red" } : null}
                  >
                    <span onClick={handleToggle}>
                      <Icon icon={icon} size={20} />
                    </span>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary btn-block mt-4 "
                onClick={submitForm}
              >
                <span
                  className={spinner}
                  role="status"
                  aria-hidden="true"
                ></span>
                Change
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
