import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
const base_url = process.env.REACT_APP_BASE_URL;

const ResetPasswordConfirm = () => {
  let { uid, token } = useParams();
  const navigate = useNavigate();
  const [login, setLogin] = useState({
    new_password: '',
    token: token,
    uid:uid
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [spinner, setSpinner] = useState(false);
  const [success, setSuccess] = useState(false);


  const handleChange = (e) => {
    setLogin({
      new_password: e.target.value,
      token: token,
      uid: uid
    });
  };

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  async function submitForm() {
    setSpinner(true);
    const item = login;
      console.log("item", item);
      let result = fetch(`${base_url}auth/users/reset_password_confirm/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      })
        .then(function (res) {
          if (res.status===204){
            navigate("/login")
            return res.json();
          }
          else{
            return res.json();
          }
        })
        .then(function (resJson) {
          console.log("resJson", resJson)
          return resJson;
        });
      result = await result;
      if (result.new_password){
        if (result.new_password[0]==="This password is too short. It must contain at least 8 characters."){
          setErrorMsg(result.new_password[0])
          setSpinner(false)
          setSuccess(false)
        }
        else if (result.new_password[0]==="This field may not be blank."){
          setErrorMsg(result.new_password[0])
          setSpinner(false)
          setSuccess(false)
        }
        else if (result.new_password[0]==="This password is too common."){
          setErrorMsg(result.new_password[0])
          setSpinner(false)
          setSuccess(false)
        }
      }
      else if (result.token){
        setErrorMsg(result.token[0])
        setSpinner(false)
        setSuccess(false)
      }
  }

  useEffect(() => {
    document.title = "Confirm Change Password";
  });

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="login-logo">
          <img
            src="/static/assets/images/gcs.png"
            className="img-fluid"
            alt="intelligens"
          />
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg ">Enter Your Password</p>
            {errorMsg.length > 0 ? (
              <p className="text-danger">{errorMsg}</p>
            ) : (
              ""
            )}
            <form onSubmit={submitForm}>
              <div className="input-group ">
                <input
                  type={type}
                  className="form-control"
                  placeholder="Password"
                  id="password"
                  name="password"
                  onChange={handleChange}
                  style={errorMsg.length > 0 ? { borderColor: "red" } : null}
                />
                <div className="input-group-append">
                  <div
                    className="input-group-text"
                    style={errorMsg.length > 0 ? { borderColor: "red" } : null}
                  >
                    <span onClick={handleToggle}>
                      <Icon icon={icon} size={20} />
                    </span>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary btn-block mt-4 "
                onClick={submitForm}
              >
                <span
                className={spinner ? "spinner-border spinner-border-sm mr-2" : ""}
                  role="status"
                  aria-hidden="true"
                ></span>
                Change
              </button>
            </form>
            {success ? (
              <p style={{ color: "green" }}>Password Successfully Changed</p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordConfirm;
