import React from "react";

function Image() {
  return (
    <>
      <div className=" text-center brand-link">
        <img
          src="/static/assets/images/noData.svg"
          style={{ height: "500px" }}
          alt="no data"
        />
      </div>
    </>
  );
}

export default Image;
