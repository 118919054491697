import React, { useState, useRef } from "react";
import JoditEditor from "jodit-react";
import { ToastContainer, toast } from "react-toastify";
import { createFaq as doCreatePost } from "./Service/post-service";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

const AddFaq = () => {
  const navigate = useNavigate();
  const editor = useRef(null);
  const [faqData, setFaq] = useState({
    title: "",
    description: "",
    status: "active",
  });
  const [titleErr, setTitleErr] = useState(false);
  const [descErr, setDescErr] = useState(false);


  const fieldChanged = (e) => {
    let item = e.target.value;
    if (item.length === 0) {
      setTitleErr(true);
    } else {
      setTitleErr(false);
    }
    setFaq({ ...faqData, [e.target.name]: e.target.value });
  };

  const descriptionChanged = (data) => {
    if (data.length === 0) {
      setDescErr(true);
    } else {
      setDescErr(false);
    }
    setFaq({ ...faqData, description: data });
  };

  const statusChanged = (event) => {
    setFaq({ ...faqData, status: event.target.value });
  };

  const createPost = (event) => {
    event.preventDefault();

    if (faqData.title.trim() === "" && faqData.description.trim() === "") {
      toast.error("faqs content is required !!");
      setDescErr(true);
      setTitleErr(true);
    }

    if (faqData.title.trim() === "") {
      toast.error("faq  title is required !!");
      setTitleErr(true);
    }

    if (faqData.description.trim() === "") {
      toast.error("faq description is required !!");
      setDescErr(true);
    }

    if (!titleErr && !descErr) {
      doCreatePost(faqData)
        .then((data) => {
          toast.success("Faqs Created !!");
          setFaq({
            title: "",
            description: "",
            status: "",
          });
          navigate("/faqlist");
        })
        .catch((error) => {
          toast.error("Faqs not created due to some error !!");
        });
    }
  };

  return (
    <>
      <section className="content main-header faq-content">
        <div className="container-fluid">
          <Helmet>
            <title>Faqs | Add faqs</title>
          </Helmet>
          <div className="row">
            <div className="col-sm-6 my-2">
              <h4 className="text-dark">FAQ's</h4>
            </div>
            <div className="col-sm-6 my-2">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/faqlist">Faqs List</a>
                </li>
                <li className="breadcrumb-item active">Add FAQs </li>
              </ol>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Add Faq's</h3>
                </div>
                <div className="card-body">
                  <form onSubmit={createPost}>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Questions</label>
                        <input
                          type="text"
                          className="form-control"
                          id="title"
                          placeholder="questions"
                          name="title"
                          onChange={fieldChanged}
                          style={titleErr ? { borderColor: "red" } : null}
                        />
                        {titleErr ? (
                          <span style={{ color: "red" }}>title is empty</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <label htmlFor="exampleInputEmail1">Answer</label>

                      <div className="mb-3">
                        <JoditEditor
                          ref={editor}
                          value={faqData.description}
                          onChange={(newDescription) =>
                            descriptionChanged(newDescription)
                          }
                        />
                        {descErr ? (
                          <span style={{ color: "red" }}>
                            description is empty
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <label>Status</label>
                      <select
                        onChange={statusChanged}
                        className="form-control select2 select2-danger"
                        name="status"
                        id="status"
                        type="select"
                        value={faqData.status}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                      <div>
                        <button
                          type="button"
                          className="btn btn-success my-3"
                          onClick={createPost}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
    </>
  );
};

export default AddFaq;
