import React from "react";
import { useNavigate } from "react-router-dom";

const NavBar = () => {

  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("user-info");
    navigate("login");
  };
  return (
    <>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="# ">
              <i className="fa fa-bars"></i>
            </a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <a href="# " className="nav-link">
              Home
            </a>
          </li>
        </ul>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="# ">
              <i className="fa fa-comments"></i>
              <span className="badge badge-danger navbar-badge">3</span>
            </a>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href=" #">
              <i className="far fa-bell"></i>
              <span className="badge badge-warning navbar-badge">15</span>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span className="dropdown-header">15 Notifications</span>
              <div className="dropdown-divider"></div>
              <a href="# " className="dropdown-item">
                <i className="fa fa-envelope mr-2"></i> 4 new messages
                <span className="float-right text-muted text-sm">3 mins</span>
              </a>
              <div className="dropdown-divider"></div>
              <a href="# " className="dropdown-item">
                <i className="fa fa-users mr-2"></i> 8 friend requests
                <span className="float-right text-muted text-sm">12 hours</span>
              </a>
              <div className="dropdown-divider"></div>
              <a href="# " className="dropdown-item">
                <i className="fa fa-file mr-2"></i> 3 new reports
                <span className="float-right text-muted text-sm">2 days</span>
              </a>
              <div className="dropdown-divider"></div>
              <a href="# " className="dropdown-item dropdown-footer">
                See All Notifications
              </a>
            </div>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="control-sidebar"
              data-slide="true"
              href="# "
              role="button"
            >
              <i className="fa fa-th-large"></i>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link" href="/update-password" role="button">
              <i className="fa-solid fa-key"></i>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="logout-btn nav-link"
              data-toggle="modal"
              data-target="#exampleModal"
              href="##"
            >
              <i className="fa-solid fa-right-from-bracket"></i>
            </a>
          </li>
        </ul>
      </nav>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {" "}
                Logout
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <span className="text-danger">
                <li className="fas fa-sign-out-alt fa-3x mt-4"></li>
              </span>
              <div className="text-danger" style={{ height: "50px" }}>
                Are you sure you want to exit the system ?
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <a
                onClick={logout}
                href="login"
                type="button"
                className="btn btn-outline-danger btn-sm"
              >
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
