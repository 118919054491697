import React from "react";

function Page404() {
  return (
    <>
      <div className="text-center ">
        <img
          src="/static/assets/images/pageNotFound.svg"
          style={{ height: "800px" }}
          alt="page not found"
        />
      </div>
    </>
  );
}
export default Page404;
