import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";

const Login = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState({
    email: "",
    password: "",
    isChecked: false,
  });
  const [errorEmail, setEmailError] = useState(false);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [redEmail, setRedEmail] = useState(false);
  const [redPassword, setRedPassword] = useState(false);
  const [spinner, setSpinner] = useState("");
  const base_url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (localStorage.checkbox && localStorage.email !== "") {
      setLogin({
        isChecked: true,
        email: localStorage.email,
        password: localStorage.password,
      });
    }
  }, []);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const fieldEmail = (e) => {
    setLogin({ ...login, email: e.target.value });
    if (validator.isEmail(login.email) && e.target.value !== "") {
      setEmailError(false);
      setRedEmail(false);
    } else {
      setEmailError(true);
      setRedEmail(true);
    }
  };

  const fieldPassword = (e) => {
    setLogin({ ...login, password: e.target.value });
    if (e.target.value !== "") {
      setRedPassword(false);
    } else {
      setRedPassword(true);
    }
  };

  const onChangeCheckbox = (e) => {
    setLogin({ ...login, isChecked: e.target.checked });
  };

  async function loginFunc() {
    let item = login;
    setSpinner("spinner-border spinner-border-sm mr-2");
    let result = fetch(`${base_url}api/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(item),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (resJson) {
        return resJson;
      });
    result = await result;
    const { email, password, isChecked } = login;
    if (result.access) {
      if (login.isChecked && email !== "") {
        localStorage.email = email;
        localStorage.password = password;
        localStorage.checkbox = isChecked;
      }
      localStorage.setItem("user-info", JSON.stringify(result));
      setRedEmail(false);
      setRedPassword(false);
      setSpinner("");
      navigate("/");
    } else {
      setRedEmail(true);
      setRedPassword(true);
      setSpinner(true);
      setSpinner("");
    }
  }
  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="login-logo">
          <img
            src="/static/assets/images/gcs.png"
            className="img-fluid"
            alt="intelligens"
          />
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Enter Your Credential</p>

            <form onSubmit={loginFunc}>
              <div className="input-group ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  value={login.email}
                  id="email"
                  name="email"
                  onChange={fieldEmail}
                  onBlur={fieldEmail}
                  style={redEmail ? { borderColor: "red" } : null}
                />
                <div className="input-group-append">
                  <div
                    className="input-group-text"
                    style={redEmail ? { borderColor: "red" } : null}
                  >
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                {errorEmail ? (
                  <span style={{ color: "red" }}>email is not valid !</span>
                ) : (
                  ""
                )}
              </div>
              <div className="input-group mb-3">
                <input
                  type={type}
                  className="form-control"
                  placeholder="Password"
                  value={login.password}
                  id="password"
                  name="password"
                  onChange={fieldPassword}
                  style={redPassword ? { borderColor: "red" } : null}
                />
                <div className="input-group-append">
                  <div
                    className="input-group-text"
                    style={redPassword ? { borderColor: "red" } : null}
                  >
                    <span onClick={handleToggle}>
                      <Icon icon={icon} size={20} />
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={login.isChecked}
                      id="flexCheckChecked"
                      onChange={onChangeCheckbox}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked"
                    >
                      Remember me
                    </label>
                  </div>
                </div>
                <div className="col-4">
                  <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={loginFunc}
                  >
                    <span
                      className={spinner}
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Sign In
                  </button>
                </div>
              </div>
            </form>
            <p className="mb-1">
              <a href="/reset-password">I forgot my password</a>
            </p>
            {redEmail || redPassword ? (
              <p style={{ color: "red" }}>credential is not valid or empty!</p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
