import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import Image from "./Image";
import { Helmet } from "react-helmet";
import { MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AuthHeader from "../AuthHeader";
import { useNavigate } from "react-router-dom";

const FaqList = () => {
  const [value, setValue] = useState("");
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const [status, setStatus] = useState();
  const [sort, setSort] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const base_url = process.env.REACT_APP_BASE_URL;

  let limit = 10;
  useEffect(() => {
    setTimeout(() => {
      getFaqs();
    }, 20);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [search, status, limit, sort, page]);

  const getFaqs = async () => {
    try {
      const res = await fetch(
        `${base_url}faqs/?page=${page}&size=${limit}&search_value=${search}&sort=${sort}`,

        {
          method: "GET",
          headers: AuthHeader(),
        }
      );
      const data = await res.json();
      const total = data.count;
      setCount(total);
      setPageCount(Math.ceil(total / limit));
      setItems(data.results);
    } catch (error) {
      Response.status = 401 ? navigate("/login") : "";
      console.log(error, "error");
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setSearch(value);
    setPage(1);
  };

  function deleteFaq(public_id) {
    fetch(`${base_url}faqs/${public_id}`, {

      method: "DELETE",
      headers: AuthHeader(),
    });
    getFaqs();
  }
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPage(currentPage);
  };

  function sortingId() {
    setSort("-id");
  }
  function sortingPublicId() {
    setSort("public_id");
  }
  function sortingQus() {
    setSort("title");
  }

  function sortingStatus() {
    setSort("status");
  }
  const statusUpdate = (public_id, status) => {
    setStatus(status + "" + public_id);
    fetch(`${base_url}faq/status/${public_id}`, {

      method: "GET",
      headers: AuthHeader(),
    });
  };

  const result = items.map((item, index) => {
    return (
      <MDBTableBody key={item.id}>
        <tr>
          <td key={index + 1}>{index + 1}</td>
          <td>
            <Link
              style={{ textDecoration: "none" }}
              to={`/faq-detail/${item.public_id}`}
            >
              {item.public_id}
            </Link>
          </td>
          <td className="question">{item.title}</td>
          <td>
            {item.status === "inactive" ? (
              <div className="custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={item.id}
                  value={item.status}
                  data-toggle="toggle"
                  unchecked="true"
                />
                <label
                  className="custom-control-label"
                  id={item.id}
                  value={item.status}
                  onClick={() => statusUpdate(item.public_id, item.status)}
                  htmlFor={item.id}
                ></label>
              </div>
            ) : (
              <div className="custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={item.id}
                  value={item.status}
                  data-toggle="toggle"
                  defaultChecked={item.status === "active"}
                />
                <label
                  className="custom-control-label"
                  value={item.status}
                  onClick={() => statusUpdate(item.public_id, item.status)}
                  htmlFor={item.id}
                ></label>
              </div>
            )}
            {item.status}
          </td>
          <td>
            <div className="dropdown show">
              <a
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                href="##"
              >
                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
              </a>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <Link
                  to={`/faq-detail/${item.public_id}`}
                  className="dropdown-item  "
                >
                  <i className="fa-solid fa-circle-info mx-2"></i>
                  Detail
                </Link>
                <Link
                  to={`/faq-edit/${item.public_id}`}
                  className="dropdown-item "
                >
                  <li className="fas fa-edit mx-2"></li>
                  Update
                </Link>
                <button
                  onClick={() => deleteFaq(item.public_id)}
                  className="dropdown-item"
                >
                  <i className="fa-sharp fa-solid fa-trash  mx-2"></i>
                  Delete
                </button>
              </div>
            </div>
          </td>
        </tr>
      </MDBTableBody>
    );
  });

  return (
    <section className="content main-header faq-content">
      <div className="container-fluid">
        <Helmet>
          <title>Faqs List | Faqs</title>
        </Helmet>
        <div className="row">
          <div className="col-sm-6 my-2">
            <h4 className="text-dark">FAQ's</h4>
          </div>
          <div className="col-sm-6 my-2">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active">FAQs List</li>
            </ol>
          </div>
          <div className="col-12">
            <div className="card faq-wrapper">
              <div className="card-header">
                <span className="font-weight-bold">FAQ's List</span>
                <a href="/add-faqs" className="float-right ">
                  <i className="fa fa-plus"></i> Add new
                </a>
              </div>
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <div>
                        <div className="input-group input-group">
                          <form
                            className="form-inline"
                            action="#"
                            method="GET"
                            onSubmit={handleSearch}
                          >
                            <div className="input-group input-group">
                              <input
                                className="form-control"
                                type="search"
                                name="search_value"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                placeholder="Search"
                                aria-label="Search"
                              />
                              <span className="input-group-append">
                                <button
                                  type="submit"
                                  name="submit"
                                  className="btn btn-info"
                                >
                                  Go!
                                </button>
                              </span>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6"></div>
                  </div>
                </div>

                <table className="table table-hover text-nowrap sortable faq-list">
                  <MDBTableHead>
                    <tr>
                      <th className="index" onClick={() => sortingId()}>
                        Index
                      </th>
                      <th
                        className="public_id"
                        onClick={() => sortingPublicId()}
                      >
                        PublicId
                      </th>
                      <th className="question" onClick={() => sortingQus()}>
                        Question
                      </th>
                      <th className="status" onClick={() => sortingStatus()}>
                        Status
                      </th>
                      <th className="action">Action</th>
                    </tr>
                  </MDBTableHead>
                  {isLoading ? (
                    <SkeletonTheme baseColor="#ebebeb" highlightColor="#f5f5f5">
                      <MDBTableBody>
                        {Array(8)
                          .fill()
                          .map((item, index) => (
                            <tr key={index}>
                              <td>
                                <h1>
                                  {" "}
                                  <Skeleton />
                                </h1>
                              </td>
                              <td>
                                <h1>
                                  {" "}
                                  <Skeleton />
                                </h1>
                              </td>
                              <td>
                                <h1>
                                  {" "}
                                  <Skeleton />
                                </h1>
                              </td>
                              <td>
                                <h1>
                                  {" "}
                                  <Skeleton />
                                </h1>
                              </td>
                              <td>
                                <h1>
                                  {" "}
                                  <Skeleton />
                                </h1>
                              </td>
                            </tr>
                          ))}
                      </MDBTableBody>
                    </SkeletonTheme>
                  ) : (
                    result
                  )}
                </table>
                {items.length === 0 ? <Image /> : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
      {count > 10 ? (
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-end pe-3"}
          activeClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-link"}
          nextClassName={"page-link"}
          activeLinkClassName={"active"}
        ></ReactPaginate>
      ) : (
        ""
      )}
    </section>
  );
};

export default FaqList;
