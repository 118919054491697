import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Helmet } from "react-helmet";
import AuthHeader from "../AuthHeader";
import Select from "react-select";

const AddRoles = () => {
  const navigate = useNavigate();
  const [roleData, setRole] = useState({
    name: "",
    permissions: [],
  });
  const [selectItem, setSelectItem] = useState([]);
  const base_url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const getPermission = async () => {
      const getPermissionName = [];
      const reqData = await fetch(`${base_url}permission/list/`, {
        headers: AuthHeader(),
      });
      const resData = await reqData.json();

      for (let i = 0; i < resData.results.length; i++) {
        getPermissionName.push({
          value: resData.results[i].id,
          label: resData.results[i].name,
        });
      }
      setSelectItem(getPermissionName);
    };
    getPermission();
  }, []);

  const fieldChanged = (e) => {
    setRole({ ...roleData, name: e.target.value });
  };
  const handleChange = (options) => {
    const ids = options.map((option) => option.value);
    setRole({ permissions: String(ids) });
  };

  const createPost = (event) => {
    event.preventDefault();

    console.log();
    const response = axios
      .post(`${base_url}role/create/`, roleData, {
        headers: AuthHeader(),
      })
      .then((response) => response.data)
      .then((data) => {
        toast.success("Roles Page Created !!");
        setRole({
          name: "",
          permissions: "",
        });
        navigate("/roles");
      })
      .catch((error) => {
        toast.error("Roles Page not created due to some error !!");
      });
  };

  return (
    <>
      <section className="content main-header content-wrapper-scroll">
        <div className="container-fluid">
          <Helmet>
            <title>Roles | Roles add</title>
          </Helmet>
          <div className="row">
            <div className="col-sm-6 my-2">
              <h4 className="text-dark">Roles Page</h4>
            </div>
            <div className="col-sm-6 my-2">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/permission">Roles List</a>
                </li>
                <li className="breadcrumb-item active">Add Roles </li>
              </ol>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Add Roles</h3>
                </div>
                <div className="card-body">
                  <form onSubmit={createPost}>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="example1">Group</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="name"
                          name="name"
                          // onChange={fieldChanged}
                          onKeyUpCapture={fieldChanged}
                        />
                      </div>
                      <label htmlFor="example2">Permission Name</label>
                      <Select
                        isMulti
                        options={selectItem}
                        onChange={handleChange}
                      />
                      <div>
                        <button
                          type="button"
                          className="btn btn-success my-3"
                          onClick={createPost}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
    </>
  );
};

export default AddRoles;
