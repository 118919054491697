import React from "react";
import { NavLink } from "react-router-dom";

const SideBarContainer = () => {
  const isActive = {
    fontWeight: "bold",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  };
  return (
    <>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <div className="login-logo logo text-center brand-link">
          <img
            src="/static/assets/images/gcs.png"
            alt="logo"
            className="img-fluid"
          />
        </div>
        <div className="sidebar">
          <div className="user-panel mt-2 pb-2 mb-2 d-flex">
            <div className="info">
              <h4 className="text-white">Admin</h4>
            </div>
          </div>
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
            >
              <li className="nav-item" activeclassname="active">
                <NavLink className="nav-link" to="/" activestyle={isActive}>
                  Dashboard
                </NavLink>
              </li>
              <li className="nav-item" activeclassname="active">
                <NavLink
                  className="nav-link"
                  to="/faqlist"
                  activestyle={isActive}
                >
                  Sarkari Yojna
                </NavLink>
              </li>
              <li className="nav-item" activeclassname="active">
                <NavLink
                  className="nav-link"
                  to="/categories"
                  activestyle={isActive}
                >
                  Category
                </NavLink>
              </li>
              <li className="nav-item" activeclassname="active">
                <NavLink className="nav-link" to="/cms" activestyle={isActive}>
                  CMS Page
                </NavLink>
              </li>
              <li className="nav-item" activeclassname="active">
                <NavLink
                  className="nav-link"
                  to="/content"
                  activestyle={isActive}
                >
                  Content
                </NavLink>
              </li>
              {/* <li className="nav-item" activeclassname="active">
                <NavLink
                  className="nav-link"
                  to="/permission"
                  activestyle={isActive}
                >
                  Permission
                </NavLink>
              </li>
              <li className="nav-item" activeclassname="active">
                <NavLink
                  className="nav-link"
                  to="/roles"
                  activestyle={isActive}
                >
                  Group
                </NavLink>
              </li> */}
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
};

export default SideBarContainer;
