import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FaqList from "./components/FaqList";
import Dashboard from "./components/Dashboard";
import AddFaq from "./components/AddFaq";
import UpdateFaqs from "./components/UpdateFaqs";
import DetailFaqs from "./components/DetailFaqs";
import ListCategory from "./components/category_component/ListCategory";
import AddCategory from "./components/category_component/AddCategory";
import DetailCategory from "./components/category_component/DetailCategory";
import UpdateCategory from "./components/category_component/UpdateCategory";
import ListCms from "./components/cms_component/ListCms";
import AddCms from "./components/cms_component/AddCms";
import UpdateCms from "./components/cms_component/UpdateCms";
import DetailCms from "./components/cms_component/DetailCms";
import Layout from "./components/Layout";
import Login from "./components/Login";
import Protected from "./components/Protected";
import Page404 from "./components/Page404";
import ListContent from "./components/content_component/ListContent";
import DetailContent from "./components/content_component/DetailContent";
import UpdateContent from "./components/content_component/UpdateContent";
import AddContent from "./components/content_component/AddContent";
import ResetPassword from "./components/ResetPassword";
import ResetPasswordConfirm from "./components/ChangePasswordConfirm";
import UpdatePassword from "./components/UpdatePassword";
import Permissions from "./permission/Permissions";
import RoleList from "./Role/RoleList";
import AddPermission from "./permission/AddPermission";
import AddRoles from "./Role/AddRoles";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Protected Component={Layout} />}>
            <Route path="/faqlist" element={<FaqList />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/add-faqs" element={<AddFaq />} />
            <Route path="/faq-edit/:public_id" element={<UpdateFaqs />} />
            <Route path="/faq-detail/:public_id" element={<DetailFaqs />} />

            <Route path="/categories" element={<ListCategory />} />
            <Route path="/add-category" element={<AddCategory />} />
            <Route path="/categories/:public_id" element={<DetailCategory />} />
            <Route
              path="/categories-update/:public_id"
              element={<UpdateCategory />}
            />
            <Route path="/update-password" element={<UpdatePassword />} />
            <Route path="/cms" element={<ListCms />} />
            <Route path="/add-cms" element={<AddCms />} />
            <Route path="/cms/:public_id" element={<DetailCms />} />
            <Route path="/cms-update/:public_id" element={<UpdateCms />} />

            <Route path="/content" element={<ListContent />} />
            <Route path="/add-content" element={<AddContent />} />
            <Route
              path="/content-detail/:public_id"
              element={<DetailContent />}
            />
            <Route
              path="/content-edit/:public_id"
              element={<UpdateContent />}
            />

            <Route path="/permission" element={<Permissions />} />
            <Route path="/add-permission" element={<AddPermission />} />

            <Route path="/roles" element={<RoleList />} />
            <Route path="/add-roles" element={<AddRoles />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/password/reset/confirm/:uid/:token"
            element={<ResetPasswordConfirm />}
          />
          <Route path="/*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;
